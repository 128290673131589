import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="チェックリスト - パフォーマンス" mdxType="SEO" />
    <PageTitle title="チェックリスト" enTitle="Checklist" mdxType="PageTitle" />
    <h2 {...{
      "id": "1-画像",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-%E7%94%BB%E5%83%8F",
        "aria-label": "1 画像 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`1. 画像`}</h2>
    <p>{`画像を閲覧する利用者にとって、ほぼ`}<strong parentName="p">{`同等品質の画像であればファイルサイズは小さい方がよい`}</strong>{`でしょう。特にモバイル回線では消費したデータ量で課金されることが多いので、より好ましいといえます。`}</p>
    <p>{`また同様に、画像は早く表示された方がよいでしょう。現在の日本での回線速度は十分に高速化されているため体感できるほど最適化するのは難しいですが、ネットワークが弱い場所や通信量の予算超過時(ギガ切れ)、3Gなどの遅い環境で利用する場合には顕著でしょう。`}</p>
    <p>{`さらに、サービスを運営する側にとっても利点があります。多くの配信サーバーでは取得数や転送量によって料金が決められています。その中でも特に画像が占める割合は多く、その`}<strong parentName="p">{`ファイルサイズが小さくなると運営費用を減らせます`}</strong>{`。`}</p>
    <p>{`NOTE: 画像容量を減らした場合には画質も必ずチェックしましょう。画質が荒くなりすぎると利用体験やコンバージョンに悪影響を及ぼす場合があります。`}</p>
    <h3 {...{
      "id": "---11-適切な画像フォーマットを選択する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#---11-%E9%81%A9%E5%88%87%E3%81%AA%E7%94%BB%E5%83%8F%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%83%E3%83%88%E3%82%92%E9%81%B8%E6%8A%9E%E3%81%99%E3%82%8B",
        "aria-label": "   11 適切な画像フォーマットを選択する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`🚨 ✅ 🎨 1.1 適切な画像フォーマットを選択する`}</h3>
    <p>{`ディスプレイサイズ・密度が多様化していることから、拡大縮小に対応できるベクター形式(SVG、iOSはPDFの場合があります)で書き出せるか検討しましょう。作成時には、パス(ポイント)をできる限り減らしておきましょう。`}</p>
    <p>{`パスが複雑な場合にはファイル容量が大きくなったり、CPU負荷が増したりすることがあるため、違う画像形式を検討します。`}</p>
    <p>{`まずはWebP形式を検討します。`}<a parentName="p" {...{
        "href": "https://caniuse.com/?search=webp"
      }}>{`ブラウザ対応`}</a>{`のためにWebP形式を利用できない場合にはJPEGまたはPNG形式を検討します。なお、実装時に複数形式の画像を配信できる場合には、WebP、AVIFなどの比較的新しい形式を利用してもいいでしょう。`}</p>
    <p>{`PNG形式を利用するときに、色数が少ないイラストなどの画像の場合はPNG-8を選択するとよいでしょう。後述する`}<a parentName="p" {...{
        "href": "https://imageoptim.com/"
      }}>{`ImageOptim`}</a>{`ではPNG形式まで選べないため、`}<a parentName="p" {...{
        "href": "https://squoosh.app/"
      }}>{`Squoosh`}</a>{`などで色数を減らします。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0e6bf0f4e076100def62307953b4eb5e/0a47e/optimize.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.625%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACe0lEQVQ4y5WS204TURSGB689XNrDHNs5dmbaOQOlBgqIBlrp9KIjhiIkDYn6IMQg3vgKxgMBVA43RJ/BGGOMiUY8xBs0PsJv9q6RG4T24kv2+rPW3mv9azOd3QO0Hr3B9cdvMf/iM9rbX9DePjidna+4sfEBrYevMf/8E+bW39N6Zn7vB67c3cTllXW0d79h6eUvLO4fYnH/5wkcYunVbyRP36H2YB9zWx9xdW0H7b3vYBprW7i2uoHZe5sUEvfK7OoG6itPEN9/RiEa47kOojCA7zoU1yn1he+50FQFuqbCc0pgwjDEwsJNdDodNOIYnufB9/1TCYIAtl3E8vIyBEHAwMAZqjPkgjCKMFwuI4qibkEQUDzfpw+chmmasItFms+4rkuFQqEAy7Jg2zaNCY7j0AKS8z9IA8Q2xzRoTEdutVpoNpuo1+uYmZlBkiRUq1QqR0XHdEb0C+fO4vxFFmFyG67rgCFeTE9Po9Fo0MvIOY5j1Go1jIyM/PPreEJw2SxYScZgfa7roa7rEEURPM9DkiQoioK8LEOWZWiaBsMwToRYZOg6JD4L3TDAcByHcrmM0dFRhEEAlmWPIK+fBJtFOpWCZZqoVsdoUwxZeTQ0jHLlErwghJjLQ5ByPUFyOUGEFw1hqh5DyuXBcDwP0bAhWR5ylgfVCaGUekd1IkiFEvzkDo3pyOl0Cpl0GpkMIQOi9QPPcRBECTwvdEcmHpINkX9H/h/xoncEcH8XKgg8GCKOV8cwOTGOqckJTIxXwWUzYHuE5GbTKeiaBkkUux7yqgmhUIJglKiP/XioOBFky0WY3IJc9LsdSnkFkqxSxLzSN0IuD9UdpOc/lVhrRXJtapsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e6bf0f4e076100def62307953b4eb5e/2aaaf/optimize.webp 160w", "/static/0e6bf0f4e076100def62307953b4eb5e/85e47/optimize.webp 320w", "/static/0e6bf0f4e076100def62307953b4eb5e/e14a1/optimize.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e6bf0f4e076100def62307953b4eb5e/69538/optimize.png 160w", "/static/0e6bf0f4e076100def62307953b4eb5e/72799/optimize.png 320w", "/static/0e6bf0f4e076100def62307953b4eb5e/0a47e/optimize.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0e6bf0f4e076100def62307953b4eb5e/0a47e/optimize.png",
              "alt": "画像圧縮ツールで色数などを調整する設定画面",
              "title": "画像圧縮ツールで色数などを調整する設定画面",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`多くのイラストは256色で十分なので、PNG形式の場合は積極的に色数の削減を試しましょう。`}</p>
    <p>{`色数の多い画像にはJPEG形式を選定するとよいでしょう。PNG-24形式は画質が綺麗ですが、容量が大きくなりすぎるため、できる限りJPEG形式を選択します。イラスト的なものでもファイルサイズを減らす上では、PNG形式ではなくJPEG形式の方が適している場合があるので、実際に変換して比較しましょう。`}</p>
    <p>{`例えば、`}<a parentName="p" {...{
        "href": "https://ameblo.jp/staff/"
      }}>{`Amebaスタッフブログ`}</a>{`のヘッダー画像の場合、PNG-24形式よりも、JPEG形式の方が軽量でした。JPEG画像の最適化には`}<a parentName="p" {...{
        "href": "https://github.com/mozilla/mozjpeg/"
      }}>{`MozJPEG`}</a>{`を利用し、品質値80で書き出しました。(ただしこの場合は色数が少ないので、PNG-8形式が最適です)`}</p>
    <p>{`元画像 (PNG形式, 41KB):
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f84018cbd74437aba665e2bd43ae8994/6b690/banner-png.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f84018cbd74437aba665e2bd43ae8994/2aaaf/banner-png.webp 160w", "/static/f84018cbd74437aba665e2bd43ae8994/85e47/banner-png.webp 320w", "/static/f84018cbd74437aba665e2bd43ae8994/75198/banner-png.webp 640w", "/static/f84018cbd74437aba665e2bd43ae8994/691bc/banner-png.webp 960w", "/static/f84018cbd74437aba665e2bd43ae8994/223e5/banner-png.webp 1280w", "/static/f84018cbd74437aba665e2bd43ae8994/b3610/banner-png.webp 2556w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f84018cbd74437aba665e2bd43ae8994/69538/banner-png.png 160w", "/static/f84018cbd74437aba665e2bd43ae8994/72799/banner-png.png 320w", "/static/f84018cbd74437aba665e2bd43ae8994/6af66/banner-png.png 640w", "/static/f84018cbd74437aba665e2bd43ae8994/d9199/banner-png.png 960w", "/static/f84018cbd74437aba665e2bd43ae8994/21b4d/banner-png.png 1280w", "/static/f84018cbd74437aba665e2bd43ae8994/6b690/banner-png.png 2556w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f84018cbd74437aba665e2bd43ae8994/6af66/banner-png.png",
              "alt": "画像のサンプル",
              "title": "画像のサンプル",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`最適化画像 (JPEG形式, 34KB):
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ccf18bd9b470824bcfdd742bea3f795c/9aa5e/banner-jpeg.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAME/9oADAMBAAIQAxAAAAG/gRxLN//EABgQAAIDAAAAAAAAAAAAAAAAAAADAhET/9oACAEBAAEFAihjp6f/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Bldv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAABEQACEP/aAAgBAQAGPwLLAFKf/8QAGBABAQEBAQAAAAAAAAAAAAAAAREAQXH/2gAIAQEAAT8hmI6vuPAlCb//2gAMAwEAAgADAAAAEAP/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxBU1//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxCV2//EABoQAAICAwAAAAAAAAAAAAAAAAABETEhUWH/2gAIAQEAAT8Qo1Lydu2km4qpFSP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ccf18bd9b470824bcfdd742bea3f795c/2aaaf/banner-jpeg.webp 160w", "/static/ccf18bd9b470824bcfdd742bea3f795c/85e47/banner-jpeg.webp 320w", "/static/ccf18bd9b470824bcfdd742bea3f795c/75198/banner-jpeg.webp 640w", "/static/ccf18bd9b470824bcfdd742bea3f795c/691bc/banner-jpeg.webp 960w", "/static/ccf18bd9b470824bcfdd742bea3f795c/223e5/banner-jpeg.webp 1280w", "/static/ccf18bd9b470824bcfdd742bea3f795c/b3610/banner-jpeg.webp 2556w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ccf18bd9b470824bcfdd742bea3f795c/0913d/banner-jpeg.jpg 160w", "/static/ccf18bd9b470824bcfdd742bea3f795c/cb69c/banner-jpeg.jpg 320w", "/static/ccf18bd9b470824bcfdd742bea3f795c/c08c5/banner-jpeg.jpg 640w", "/static/ccf18bd9b470824bcfdd742bea3f795c/6a068/banner-jpeg.jpg 960w", "/static/ccf18bd9b470824bcfdd742bea3f795c/eea4a/banner-jpeg.jpg 1280w", "/static/ccf18bd9b470824bcfdd742bea3f795c/9aa5e/banner-jpeg.jpg 2556w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ccf18bd9b470824bcfdd742bea3f795c/c08c5/banner-jpeg.jpg",
              "alt": "画像のサンプル",
              "title": "画像のサンプル",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`最適化画像 (PNG-8形式, 20KB):
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f479db1dc092cad1a51fd84fec3108e1/6b690/banner-png8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f479db1dc092cad1a51fd84fec3108e1/2aaaf/banner-png8.webp 160w", "/static/f479db1dc092cad1a51fd84fec3108e1/85e47/banner-png8.webp 320w", "/static/f479db1dc092cad1a51fd84fec3108e1/75198/banner-png8.webp 640w", "/static/f479db1dc092cad1a51fd84fec3108e1/691bc/banner-png8.webp 960w", "/static/f479db1dc092cad1a51fd84fec3108e1/223e5/banner-png8.webp 1280w", "/static/f479db1dc092cad1a51fd84fec3108e1/b3610/banner-png8.webp 2556w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f479db1dc092cad1a51fd84fec3108e1/69538/banner-png8.png 160w", "/static/f479db1dc092cad1a51fd84fec3108e1/72799/banner-png8.png 320w", "/static/f479db1dc092cad1a51fd84fec3108e1/6af66/banner-png8.png 640w", "/static/f479db1dc092cad1a51fd84fec3108e1/d9199/banner-png8.png 960w", "/static/f479db1dc092cad1a51fd84fec3108e1/21b4d/banner-png8.png 1280w", "/static/f479db1dc092cad1a51fd84fec3108e1/6b690/banner-png8.png 2556w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f479db1dc092cad1a51fd84fec3108e1/6af66/banner-png8.png",
              "alt": "画像のサンプル",
              "title": "画像のサンプル",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "--12-アニメーション用途には動画形式を利用する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#--12-%E3%82%A2%E3%83%8B%E3%83%A1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E7%94%A8%E9%80%94%E3%81%AB%E3%81%AF%E5%8B%95%E7%94%BB%E5%BD%A2%E5%BC%8F%E3%82%92%E5%88%A9%E7%94%A8%E3%81%99%E3%82%8B",
        "aria-label": "  12 アニメーション用途には動画形式を利用する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 🎨 1.2 アニメーション用途には動画形式を利用する`}</h3>
    <p>{`アニメーション用途には、できる限り動画ファイルを利用します。特にアニメーションGIFは、ファイルサイズが大きくなりやすいほか、CPUに負荷をかけがちなため、利用を推奨していません。`}</p>
    <p>{`アニメーションに対応した画像ファイルを利用する場合には、フレーム数を少なくする、画像サイズを小さくするなど工夫してください。`}</p>
    <h3 {...{
      "id": "---13-画像を最適化する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#---13-%E7%94%BB%E5%83%8F%E3%82%92%E6%9C%80%E9%81%A9%E5%8C%96%E3%81%99%E3%82%8B",
        "aria-label": "   13 画像を最適化する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`🚨 ✅ 🎨 1.3 画像を最適化する`}</h3>
    <p>{`同じ形式の画像でも適切に圧縮することで見た目はほぼ変わらないまま、ファイルサイズを削減できます。まず`}<a parentName="p" {...{
        "href": "https://imageoptim.com/"
      }}>{`ImageOptim`}</a>{`で最適化し、それでもファイルサイズが大きい場合は、`}<a parentName="p" {...{
        "href": "https://squoosh.app/"
      }}>{`Squoosh`}</a>{`などを利用して画像形式や画像品質を調整します。Amebaではファイルサイズが40KBを超える場合にSquooshの利用を推奨しています。この値は暫定的な基準値であり、将来的に変更される可能性があります。`}</p>
    <p>{`また、画像を配信する際には自動的に最適化してくれるサービス(Akamai Image Manager、Fastly Image Optimzer、imgixなど)を併用するとよいでしょう。ただし、低画質の画像を変換すると画質が悪化するので自動的に変換する場合は、オリジナルの画像をできる限り高品質にすると良いでしょう。理想的にはLossless画像(PNGやTIFF)から変換することが好ましいですが、変換がかからなかった場合やディスク容量、転送量などを踏まえて高画質なJPEGをオリジナルとしておくことも現実的にはありでしょう。`}</p>
    <p>{`NOTE: Squooshで変換する場合、変換方法は「Browser*」でないものを選択してください。Browserの変換は最適化目的ではありません。また、PNG形式を変換する場合には、デフォルトの設定ではImageOptimと容量は変わりません。より減らしたい場合には、「Reduce palette」オプションを指定してください。ただし画質は劣化する場合がありますので必ず目視してください。`}</p>
    <p>{`NOTE: 画像の品質を比較する際には、SSIMやMSE/PSNRなど機械的な方法で比較するのもよいでしょう。一般的によく使われているSSIMでは、0.999(暫定値)を下回る場合には再度調整を検討しましょう。それらはImageMagick、FFmpeg、Image SSIMなどのツールで測定できます。ただし、自動的に比較しきれないパターンもあるので必ず目視もしましょう。`}</p>
    <h3 {...{
      "id": "--14-表示に必要なサイズで画像をリクエストする",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#--14-%E8%A1%A8%E7%A4%BA%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%A7%E7%94%BB%E5%83%8F%E3%82%92%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B",
        "aria-label": "  14 表示に必要なサイズで画像をリクエストする permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`🚨 ✅ 1.4 表示に必要なサイズで画像をリクエストする`}</h3>
    <p>{`表示領域を大幅に超える画像を取得、表示することは、ネットワークの転送量、端末のメモリ・ストレージなどの無駄に繋がります。例えば、横幅200px、縦幅100pxで表示したい領域に対して横幅1200px・縦幅600pxの画像を配信するのは大きすぎます。`}</p>
    <p>{`近年ではディスプレイピクセル比の向上により拡大した画像を用意する必要もありますが、例外を除きAmebaでは、最大でも2倍サイズを用意すればよいこととします。`}</p>
    <p>{`ただし、拡大用途など利用者が明らかに高画質な画像を望んでいるシチュエーション、文字がぼやけてしまうと困る場合などでは端末のピクセル比に応じたサイズを用意してもよいでしょう。逆に、常に小さく表示されるサムネイル画像は1倍の画像のみを用意することも考えられます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7255a5a98057d4015c0324cdfd8877de/5ff7e/skelton.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAl0lEQVQoz63PzQ7CIBBGUd6+ddGuMSE8ozADBGbGmsLCxBodf06+LXAx28FVzXjvrbXnzlrrnAOAccX2jlnXdZqmUzfP87IsIQTt4e0HJkS4hNi6WvfxTl5vdBnABJhEhJlJTUT2w8RMzJofPsnGlDFl+dBjNjE3Is3qPbv7MjuXkksZKSLajbdMRIyALELErZFmtf4j+wYvT/OpyJMrQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7255a5a98057d4015c0324cdfd8877de/2aaaf/skelton.webp 160w", "/static/7255a5a98057d4015c0324cdfd8877de/85e47/skelton.webp 320w", "/static/7255a5a98057d4015c0324cdfd8877de/0669f/skelton.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7255a5a98057d4015c0324cdfd8877de/69538/skelton.png 160w", "/static/7255a5a98057d4015c0324cdfd8877de/72799/skelton.png 320w", "/static/7255a5a98057d4015c0324cdfd8877de/5ff7e/skelton.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7255a5a98057d4015c0324cdfd8877de/5ff7e/skelton.png",
              "alt": "リストのサムネイル画像のイメージ",
              "title": "リストのサムネイル画像のイメージ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`リストの画像は、小さく表示され高画質な画像が要求されないので、1倍かつ品質を落とします。`}</p>
    <h3 {...{
      "id": "-15-同一画像を複数サイズで配信する場合にはキャッシュ効率を考慮する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-15-%E5%90%8C%E4%B8%80%E7%94%BB%E5%83%8F%E3%82%92%E8%A4%87%E6%95%B0%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%A7%E9%85%8D%E4%BF%A1%E3%81%99%E3%82%8B%E5%A0%B4%E5%90%88%E3%81%AB%E3%81%AF%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E5%8A%B9%E7%8E%87%E3%82%92%E8%80%83%E6%85%AE%E3%81%99%E3%82%8B",
        "aria-label": " 15 同一画像を複数サイズで配信する場合にはキャッシュ効率を考慮する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 1.5 同一画像を複数サイズで配信する場合には、キャッシュ効率を考慮する`}</h3>
    <p>{`1.4で触れたように、端末できれいに画像を表示するためにはディスプレイピクセル比ごとに複数サイズの画像を用意するとよいですが、その場合には種類の作り過ぎに注意しましょう。`}</p>
    <p>{`今では数え切れないほどの画面サイズがあり、それぞれに画像ファイルを用意しようとすると、`}<inlineCode parentName="p">{`?size=320`}</inlineCode>{`、`}<inlineCode parentName="p">{`?size=470`}</inlineCode>{`、`}<inlineCode parentName="p">{`?size=800`}</inlineCode>{`、`}<inlineCode parentName="p">{`?size=1024`}</inlineCode>{`などのように多くの画像URLができます。`}</p>
    <p>{`これは、サーバーサイドとクライアントサイドでのキャッシュ効率が悪化し、結果的に表示速度が遅くなるためです。多くの場合、`}<inlineCode parentName="p">{`https://example.com/image.jpg?size=800`}</inlineCode>{`といったURL文字列を識別子としてキャッシュを再利用しています。そのため、サーバーサイドでは再度画像を生成しなければならなかったり、クライアンサイドでは端末上のキャッシュではなくネットワークを経由して画像を取得しなければなりません。`}</p>
    <p>{`キャッシュ効率を向上させるためには、画像サイズの種類を限定することが有効です。例えば、大中小で3つくらいの種類を用意し、メイン画像・リストカード・サムネイルなどに用途をわけると良いでしょう。`}</p>
    <h3 {...{
      "id": "-16-すぐに読み込む必要のない画像は遅延リクエストする",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-16-%E3%81%99%E3%81%90%E3%81%AB%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%82%80%E5%BF%85%E8%A6%81%E3%81%AE%E3%81%AA%E3%81%84%E7%94%BB%E5%83%8F%E3%81%AF%E9%81%85%E5%BB%B6%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B",
        "aria-label": " 16 すぐに読み込む必要のない画像は遅延リクエストする permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 1.6 すぐに読み込む必要のない画像は遅延リクエストする`}</h3>
    <p>{`すぐに読み込む必要のない画像は、スクロールに応じて要素が画面表示に近付くにつれて画像を取得します。そうするとネットワーク転送量を減らせ、さらに他の要素の表示が早くなる可能性もあります。`}</p>
    <p>{`下記はWeb実装例です。`}</p>
    <pre><code parentName="pre" {...{}}>{`<!-- 遅延表示しない画像はloading="eager"を付与する -->
<img slt="" height="100" loading="eager" src="https://example.com/eager.jpg" width="300">

<!--
  遅延表示する画像は以下の対応をする
  1. srcをdata-srcにする
  2. loading="lazy"を付与する
  3. 壊れた画像のアイコンが表示されないようにsrcに透明な画像をセットする
  4. JavaScriptが使えないブラウザ用に<noscript>を用意する (オプション)
-->
<img alt="" height="200" data-src="https://example.com/lazy.jpg" src="data:image/svg\\\\+xml;charset=utf-8,<svg title='Image Placeholder' xmlns='http://www.w3.org/2000/svg' role='presentation' viewBox='0 0 100 200' />" loading="lazy" width="100">
<noscript><img alt="" height="200" src="https://example.com/lazy.jpg" loading="lazy" width="100"></noscript>

<script>
  (function() {
    const lazyImages = document.querySelectorAll("img[loading=lazy]");

    if ("loading" in HTMLImageElement.prototype) {
      lazyImages.forEach(function (lazyImage) {
        lazyImage.setAttribute('src', lazyImage.dataset.src);
      });
    } else {
      // 選択肢1. lazyLoadライブラリを使う
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://cdn.jsdelivr.net/npm/vanilla-lazyload@17.1.0/dist/lazyload.min.js";
      window.lazyLoadOptions = {
        elements_selector: "[loading=lazy]"
      };
      document.body.appendChild(script);

      // 選択肢2. Insersection Observerを使う
      if ('IntersectionObserver' in window) {
        const observer = new window.IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const lazyImage = entry.target;
              lazyImage.setAttribute('src', lazyImage.dataset.src);
              observer.unobserve(lazyImage);
            }
          });
        });

        lazyImages.forEach((lazyImage) => {
          observer.observe(lazyImage);
        }
      } else {
        lazyImages.forEach(function (lazyImage) {
         lazyImage.setAttribute('src', lazyImage.dataset.src);
        });
      }
    }
  })();
</script>
`}</code></pre>
    <p>{`NOTE: 画像を読み込む間にプレースホルダーを表示するProgressive renderingという手法があります。プロジェクトの特性に応じて必要か選択して利用してください。テキスト主体で画像がメインと考えられない場合は、表示領域を確保し、CSSや軽量の共通画像で背景を指定するだけで十分かもしれません。フォトギャラリーなど画像の表示がサイトのコンバージョンに影響する場合には、Progressive JPEG、LQIP，SQIPなどを利用すると良いでしょう。ただし処理が多くなり表示の遅延につながる可能性があるため、控えめに利用してください。`}</p>
    {
      /* ### ✅ 1.7 利用者の設定を尊重して、低画質な画像を表示する (WIP) */
    }
    <h2 {...{
      "id": "2-webフォント",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-web%E3%83%95%E3%82%A9%E3%83%B3%E3%83%88",
        "aria-label": "2 webフォント permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`2. Webフォント`}</h2>
    <p>{`Webフォントはデザインされたフォントでコンテンツを表示できることから、見た目上のメリットがありますがその反面、パフォーマンス上のデメリットも大きいので、慎重に利用します。特に日本語フォントは文字数も多いため、積極的に利用するのは難しいでしょう。`}</p>
    <h3 {...{
      "id": "---21-ameba共通ルールに従っているか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#---21-ameba%E5%85%B1%E9%80%9A%E3%83%AB%E3%83%BC%E3%83%AB%E3%81%AB%E5%BE%93%E3%81%A3%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B",
        "aria-label": "   21 ameba共通ルールに従っているか permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`🚨 ✅ 🎨 2.1 Ameba共通ルールに従っているか`}</h3>
    <p>{`Amebaで提供するフォントに関しては、以下のルールに従っているか確認してください。`}</p>
    <ul>
      <li parentName="ul">{`数字のフォントがそのコンテンツにとって極めて重要な場合は、Ameba Numbers(数字のみ収納されたWebフォント)を利用可能です。Webフォントとして利用する際には、デバイスフォントでも表示が可能なように設計し、CSSで`}<inlineCode parentName="li">{`font-display: optional`}</inlineCode>{`を指定します。ただし利用する数字が少ない場合(例えばランキング用途で1から3まで)は、SVGかデバイスフォントで表示します`}</li>
      <li parentName="ul">{`それ以外の英字、記号、日本語を含むフォントは個別文字でSVG化して利用します`}
        <ul parentName="li">
          <li parentName="ul">{`ただし、後述するサブセット化が可能な場合は、ファイルサイズに注意して利用します`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "-22-サブセット化されているか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-22-%E3%82%B5%E3%83%96%E3%82%BB%E3%83%83%E3%83%88%E5%8C%96%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B",
        "aria-label": " 22 サブセット化されているか permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 2.2 サブセット化されているか`}</h3>
    <p>{`Webフォントは、表示に必要な文字だけを含むフォントファイルを作成して、ファイルサイズを減らせます。そうすると、表示が早くなるほか、無駄なネットワーク転送量も減らせます。`}</p>
    <p>{`例えば、Google Fontsではtextパラメータで表示に必要な文字を指定できます。`}</p>
    <pre><code parentName="pre" {...{}}>{`https://fonts.googleapis.com/css2family=Comfortaa&amp;text=Hello
`}</code></pre>
    <h2 {...{
      "id": "3-レイアウトシフト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88%E3%82%B7%E3%83%95%E3%83%88",
        "aria-label": "3 レイアウトシフト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`3 レイアウトシフト`}</h2>
    <p>{`レイアウトシフトは要素のサイズ変更を起点に、画面内のレイアウトが変更されることです。これは誤タップや、閲覧している要素が画面外へ消えてしまうなどユーザー体験を著しく損なう原因となります。これらが何度も発生すると、コンバージョンの低下やサービスへの信頼感の低下につながるため、できる限りなくしたいものです。`}</p>
    <figure>
  <video src="/video/layout-shift.mp4" controls height="434" width="200"></video>
  <figcaption>キャンセルボタンを押そうとしている時に、レイアウトシフトが起き、購読ボタンを誤タップしてしまった例を表現したアニメーション</figcaption>
    </figure>
    <figure>
  <video src="/video/layout-shift2.mp4" controls height="434" width="200"></video>
  <figcaption>スクロールして記事を読んでいる際に、画面上部に遅れて大きな要素が表示され、レイアウトシフトが起きる例を表現したアニメーション</figcaption>
    </figure>
    <p>{`スクロールして記事を読んでいる際に、画面上部に遅れて大きな要素が表示されるとレイアウトシフトが起きます。利用者は、どこを読んでいたのかわからなくなってしまい、またその位置へ戻るという無駄な手間をかけなければなりません。`}</p>
    <h3 {...{
      "id": "-31-要素のサイズを指定する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-31-%E8%A6%81%E7%B4%A0%E3%81%AE%E3%82%B5%E3%82%A4%E3%82%BA%E3%82%92%E6%8C%87%E5%AE%9A%E3%81%99%E3%82%8B",
        "aria-label": " 31 要素のサイズを指定する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 3.1 要素のサイズを指定する`}</h3>
    <p>{`画像や埋め込みオブジェクトなどは縦横比がわかるように、縦サイズ、横サイズを指定しましょう。`}</p>
    <pre><code parentName="pre" {...{}}>{`<!-- 画像や埋め込み要素には縦サイズ(height)と横サイズ(width)を必ず指定します -->
<img height="200" width="300" alt="" src="image.png">
<iframe height="400" width="300" title="" src="frame.html"></iframe>
`}</code></pre>
    <p>{`要素のサイズを指定すると、予め表示領域が確保されます。要素の取得が遅くても、レイアウトは変わらず表示されます。
要素のサイズを指定しないと、要素の取得時に表示領域が確保され、画面内のレイアウトが大きく変更されます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f9325b73a1bb51e858ae95c433bc02b0/5ff7e/layout-shift-do.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f9325b73a1bb51e858ae95c433bc02b0/2aaaf/layout-shift-do.webp 160w", "/static/f9325b73a1bb51e858ae95c433bc02b0/85e47/layout-shift-do.webp 320w", "/static/f9325b73a1bb51e858ae95c433bc02b0/0669f/layout-shift-do.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f9325b73a1bb51e858ae95c433bc02b0/69538/layout-shift-do.png 160w", "/static/f9325b73a1bb51e858ae95c433bc02b0/72799/layout-shift-do.png 320w", "/static/f9325b73a1bb51e858ae95c433bc02b0/5ff7e/layout-shift-do.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f9325b73a1bb51e858ae95c433bc02b0/5ff7e/layout-shift-do.png",
              "alt": "要素のサイズを指定している良い例のアニメーション",
              "title": "要素のサイズを指定している良い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DO: 要素のサイズを指定すると、予め表示領域が確保されます。要素の取得が遅くても、レイアウトは変わらず表示されます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/570ebdb1a0908049da8a3626a3be4f35/5ff7e/layout-shift-dont-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/570ebdb1a0908049da8a3626a3be4f35/2aaaf/layout-shift-dont-1.webp 160w", "/static/570ebdb1a0908049da8a3626a3be4f35/85e47/layout-shift-dont-1.webp 320w", "/static/570ebdb1a0908049da8a3626a3be4f35/0669f/layout-shift-dont-1.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/570ebdb1a0908049da8a3626a3be4f35/69538/layout-shift-dont-1.png 160w", "/static/570ebdb1a0908049da8a3626a3be4f35/72799/layout-shift-dont-1.png 320w", "/static/570ebdb1a0908049da8a3626a3be4f35/5ff7e/layout-shift-dont-1.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/570ebdb1a0908049da8a3626a3be4f35/5ff7e/layout-shift-dont-1.png",
              "alt": "要素のサイズを指定していない悪い例のアニメーション",
              "title": "要素のサイズを指定していない悪い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DON'T: 要素のサイズを指定しないと、要素の取得時に表示領域が確保され、画面内のレイアウトが大きく変更されます。`}</p>
    <h3 {...{
      "id": "--32-複数サイズの要素が入る領域には最大サイズに合わせてレイアウト指定する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#--32-%E8%A4%87%E6%95%B0%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%AE%E8%A6%81%E7%B4%A0%E3%81%8C%E5%85%A5%E3%82%8B%E9%A0%98%E5%9F%9F%E3%81%AB%E3%81%AF%E6%9C%80%E5%A4%A7%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%AB%E5%90%88%E3%82%8F%E3%81%9B%E3%81%A6%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88%E6%8C%87%E5%AE%9A%E3%81%99%E3%82%8B",
        "aria-label": "  32 複数サイズの要素が入る領域には最大サイズに合わせてレイアウト指定する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 🎨 3.2 複数サイズの要素が入る領域には、最大サイズに合わせてレイアウト指定する`}</h3>
    <p>{`例えば、100pxと250pxの高さの要素が表示される領域には、最大値の250pxに上下余白を加えた合計値を高さとして指定する必要があります。`}</p>
    <p>{`Amebaでは広告向けにこの対応をしていますが、表示されうる広告のサイズが変わるたびに見直す必要があります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/662d656e5a3bb4a280b72e4ec466edd2/5ff7e/layout-shift-do-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/662d656e5a3bb4a280b72e4ec466edd2/2aaaf/layout-shift-do-2.webp 160w", "/static/662d656e5a3bb4a280b72e4ec466edd2/85e47/layout-shift-do-2.webp 320w", "/static/662d656e5a3bb4a280b72e4ec466edd2/0669f/layout-shift-do-2.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/662d656e5a3bb4a280b72e4ec466edd2/69538/layout-shift-do-2.png 160w", "/static/662d656e5a3bb4a280b72e4ec466edd2/72799/layout-shift-do-2.png 320w", "/static/662d656e5a3bb4a280b72e4ec466edd2/5ff7e/layout-shift-do-2.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/662d656e5a3bb4a280b72e4ec466edd2/5ff7e/layout-shift-do-2.png",
              "alt": "並ぶ要素の最大値を指定した良い例のアニメーション",
              "title": "並ぶ要素の最大値を指定した良い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DO: 複数サイズの要素が表示される可能性のある領域では、大きい方に合わせて余白を指定します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/72d43184211547411d67f64af83d3624/5ff7e/layout-shift-dont-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/72d43184211547411d67f64af83d3624/2aaaf/layout-shift-dont-2.webp 160w", "/static/72d43184211547411d67f64af83d3624/85e47/layout-shift-dont-2.webp 320w", "/static/72d43184211547411d67f64af83d3624/0669f/layout-shift-dont-2.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/72d43184211547411d67f64af83d3624/69538/layout-shift-dont-2.png 160w", "/static/72d43184211547411d67f64af83d3624/72799/layout-shift-dont-2.png 320w", "/static/72d43184211547411d67f64af83d3624/5ff7e/layout-shift-dont-2.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/72d43184211547411d67f64af83d3624/5ff7e/layout-shift-dont-2.png",
              "alt": "並ぶ要素の最大値を指定していない悪い例のアニメーション",
              "title": "並ぶ要素の最大値を指定していない悪い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DON'T: 小さいサイズに合わせて余白を指定すると、大きいサイズの要素が表示された時にレイアウトシフトが起こります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3b1a95fece2e4f042707e65073ce342f/5ff7e/layout-shift-do-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3b1a95fece2e4f042707e65073ce342f/2aaaf/layout-shift-do-3.webp 160w", "/static/3b1a95fece2e4f042707e65073ce342f/85e47/layout-shift-do-3.webp 320w", "/static/3b1a95fece2e4f042707e65073ce342f/0669f/layout-shift-do-3.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3b1a95fece2e4f042707e65073ce342f/69538/layout-shift-do-3.png 160w", "/static/3b1a95fece2e4f042707e65073ce342f/72799/layout-shift-do-3.png 320w", "/static/3b1a95fece2e4f042707e65073ce342f/5ff7e/layout-shift-do-3.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3b1a95fece2e4f042707e65073ce342f/5ff7e/layout-shift-do-3.png",
              "alt": "リストアイテムが最大数表示されることを想定した良い例のアニメーション",
              "title": "リストアイテムが最大数表示されることを想定した良い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DO: リストを利用する場合には、表示したい件数分の領域を確保します。情報設計、UI設計の段階から考慮しましょう。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0902ec145ac49f966e88eaea46413fdb/5ff7e/layout-shift-dont-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0902ec145ac49f966e88eaea46413fdb/2aaaf/layout-shift-dont-3.webp 160w", "/static/0902ec145ac49f966e88eaea46413fdb/85e47/layout-shift-dont-3.webp 320w", "/static/0902ec145ac49f966e88eaea46413fdb/0669f/layout-shift-dont-3.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0902ec145ac49f966e88eaea46413fdb/69538/layout-shift-dont-3.png 160w", "/static/0902ec145ac49f966e88eaea46413fdb/72799/layout-shift-dont-3.png 320w", "/static/0902ec145ac49f966e88eaea46413fdb/5ff7e/layout-shift-dont-3.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0902ec145ac49f966e88eaea46413fdb/5ff7e/layout-shift-dont-3.png",
              "alt": "リストアイテムが最大数表示されることを想定していない悪い例のアニメーション",
              "title": "リストアイテムが最大数表示されることを想定していない悪い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DON'T: あらかじめ3件分の要素が確保されているリストに、5件の内容を表示するとレイアウトシフトが起こります。`}</p>
    <h3 {...{
      "id": "--プレースホルダー取得前取得中やフォールバック0件取得失敗コンテンツを設定する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#--%E3%83%97%E3%83%AC%E3%83%BC%E3%82%B9%E3%83%9B%E3%83%AB%E3%83%80%E3%83%BC%E5%8F%96%E5%BE%97%E5%89%8D%E5%8F%96%E5%BE%97%E4%B8%AD%E3%82%84%E3%83%95%E3%82%A9%E3%83%BC%E3%83%AB%E3%83%90%E3%83%83%E3%82%AF0%E4%BB%B6%E5%8F%96%E5%BE%97%E5%A4%B1%E6%95%97%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
        "aria-label": "  プレースホルダー取得前取得中やフォールバック0件取得失敗コンテンツを設定する permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 🎨 プレースホルダー(取得前、取得中)やフォールバック(0件、取得失敗)コンテンツを設定する`}</h3>
    <p>{`データ取得前、データ取得中、データ取得後、エラー時などの状態で要素の表示内容が変わる場合にも、レイアウトシフトを防ぐために同じサイズの領域内でコンテンツを表示します。`}</p>
    <p>{`このレイアウトシフトが起こりやすい要素としては、0件とそれ以外の件数で表示が異なる要素、状態に応じて表示を変えるボタンなどがあります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b52e6307ac0fc0ed58ae629cf81080f3/5ff7e/layout-shift-do-4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b52e6307ac0fc0ed58ae629cf81080f3/2aaaf/layout-shift-do-4.webp 160w", "/static/b52e6307ac0fc0ed58ae629cf81080f3/85e47/layout-shift-do-4.webp 320w", "/static/b52e6307ac0fc0ed58ae629cf81080f3/0669f/layout-shift-do-4.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b52e6307ac0fc0ed58ae629cf81080f3/69538/layout-shift-do-4.png 160w", "/static/b52e6307ac0fc0ed58ae629cf81080f3/72799/layout-shift-do-4.png 320w", "/static/b52e6307ac0fc0ed58ae629cf81080f3/5ff7e/layout-shift-do-4.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b52e6307ac0fc0ed58ae629cf81080f3/5ff7e/layout-shift-do-4.png",
              "alt": "データ取得が失敗したときに領域の大きさを残した良い例のアニメーション",
              "title": "データ取得が失敗したときに領域の大きさを残した良い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DO: データ取得が失敗した時には、要素の領域はそのままで、詳細の情報を表示します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/74608dd0a16d1ce1f6b1d9f7288df44c/5ff7e/layout-shift-dont-4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/74608dd0a16d1ce1f6b1d9f7288df44c/2aaaf/layout-shift-dont-4.webp 160w", "/static/74608dd0a16d1ce1f6b1d9f7288df44c/85e47/layout-shift-dont-4.webp 320w", "/static/74608dd0a16d1ce1f6b1d9f7288df44c/0669f/layout-shift-dont-4.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/74608dd0a16d1ce1f6b1d9f7288df44c/69538/layout-shift-dont-4.png 160w", "/static/74608dd0a16d1ce1f6b1d9f7288df44c/72799/layout-shift-dont-4.png 320w", "/static/74608dd0a16d1ce1f6b1d9f7288df44c/5ff7e/layout-shift-dont-4.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/74608dd0a16d1ce1f6b1d9f7288df44c/5ff7e/layout-shift-dont-4.png",
              "alt": "データ取得が失敗したときに領域の大きさを残さない悪い例のアニメーション",
              "title": "データ取得が失敗したときに領域の大きさを残さない悪い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DON'T: データ取得が失敗した時に、要素ごと消してしまうと、レイアウトシフトが起こります。`}</p>
    <p>{`複数の状態でも同じサイズで要素を表示できるようにします。レイアウトシフトは縦方向だけでなく、横方向にも注意します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/246e2f12d6e18678d812dd02a304fb02/5ff7e/layout-shift-do-5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/246e2f12d6e18678d812dd02a304fb02/2aaaf/layout-shift-do-5.webp 160w", "/static/246e2f12d6e18678d812dd02a304fb02/85e47/layout-shift-do-5.webp 320w", "/static/246e2f12d6e18678d812dd02a304fb02/0669f/layout-shift-do-5.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/246e2f12d6e18678d812dd02a304fb02/69538/layout-shift-do-5.png 160w", "/static/246e2f12d6e18678d812dd02a304fb02/72799/layout-shift-do-5.png 320w", "/static/246e2f12d6e18678d812dd02a304fb02/5ff7e/layout-shift-do-5.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/246e2f12d6e18678d812dd02a304fb02/5ff7e/layout-shift-do-5.png",
              "alt": "ログイン状態に関わらず同じサイズの要素を配置する良い例のアニメーション",
              "title": "ログイン状態に関わらず同じサイズの要素を配置する良い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DO: 非ログイン時にはログインボタン、ログイン時には同じサイズでサムネイルとニックネームを表示します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f204215ffffa5fe3c1fc2d0f3fb61075/5ff7e/layout-shift-dont-5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f204215ffffa5fe3c1fc2d0f3fb61075/2aaaf/layout-shift-dont-5.webp 160w", "/static/f204215ffffa5fe3c1fc2d0f3fb61075/85e47/layout-shift-dont-5.webp 320w", "/static/f204215ffffa5fe3c1fc2d0f3fb61075/0669f/layout-shift-dont-5.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f204215ffffa5fe3c1fc2d0f3fb61075/69538/layout-shift-dont-5.png 160w", "/static/f204215ffffa5fe3c1fc2d0f3fb61075/72799/layout-shift-dont-5.png 320w", "/static/f204215ffffa5fe3c1fc2d0f3fb61075/5ff7e/layout-shift-dont-5.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f204215ffffa5fe3c1fc2d0f3fb61075/5ff7e/layout-shift-dont-5.png",
              "alt": "ログイン状態によって要素や配置が変わる悪い例のアニメーション",
              "title": "ログイン状態によって要素や配置が変わる悪い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DON'T: ログイン中であった時に、ログインボタンだけ消すとレイアウトシフトが発生します。`}</p>
    <h3 {...{
      "id": "--インフィニティスクロールする場合にはプレースホルダーを用意し下に要素を配置しない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#--%E3%82%A4%E3%83%B3%E3%83%95%E3%82%A3%E3%83%8B%E3%83%86%E3%82%A3%E3%82%B9%E3%82%AF%E3%83%AD%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B%E5%A0%B4%E5%90%88%E3%81%AB%E3%81%AF%E3%83%97%E3%83%AC%E3%83%BC%E3%82%B9%E3%83%9B%E3%83%AB%E3%83%80%E3%83%BC%E3%82%92%E7%94%A8%E6%84%8F%E3%81%97%E4%B8%8B%E3%81%AB%E8%A6%81%E7%B4%A0%E3%82%92%E9%85%8D%E7%BD%AE%E3%81%97%E3%81%AA%E3%81%84",
        "aria-label": "  インフィニティスクロールする場合にはプレースホルダーを用意し下に要素を配置しない permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ 🎨 インフィニティスクロールする場合には、プレースホルダーを用意し、下に要素を配置しない`}</h3>
    <p>{`インフィニティスクロールを使ってコンテンツを追加する際、その下に要素があるとレイアウトシフトします。例えば、フッターがページ下部にあるとインフィニティスクロールをすると、その要素が操作できなくなってしまいます。`}</p>
    <p>{`これらを解決するためには、まずそのページでインフィニティスクロールが必要か考慮します。多くの情報が必要とされていない場合や、データ転送量が多いと想定される場合には、ページングへ変更することを考えてもよいでしょう。`}</p>
    <p>{`インフィニティスクロールを改善する場合には、下に要素を配置せず他の表示方法にします。例えば画面ページ下部に固定して表示されるようにしたり、メニュー内など他の場所から参照できるようにしたりします。`}</p>
    <p>{`さらに、インフィニティスクロールで表示されるコンテンツを早めに取得したり、プレースホルダーを用意したりして画面に何も表示されない時間をなくします。`}</p>
    <p>{`その他にも、それ以上スクロールしてもコンテンツが無い最後のタイミングでフッターを表示する方法も考えられます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fdc9c72fa8106638161cbf650e1cd4d3/5ff7e/layout-shift-do-6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fdc9c72fa8106638161cbf650e1cd4d3/2aaaf/layout-shift-do-6.webp 160w", "/static/fdc9c72fa8106638161cbf650e1cd4d3/85e47/layout-shift-do-6.webp 320w", "/static/fdc9c72fa8106638161cbf650e1cd4d3/0669f/layout-shift-do-6.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fdc9c72fa8106638161cbf650e1cd4d3/69538/layout-shift-do-6.png 160w", "/static/fdc9c72fa8106638161cbf650e1cd4d3/72799/layout-shift-do-6.png 320w", "/static/fdc9c72fa8106638161cbf650e1cd4d3/5ff7e/layout-shift-do-6.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fdc9c72fa8106638161cbf650e1cd4d3/5ff7e/layout-shift-do-6.png",
              "alt": "良い例のアニメーション",
              "title": "良い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DO: インフィニティスクロールで要素を表示する際には、下部に要素を配置しないようにします。また読み込み中の要素にもプレースホルダーとともに固定サイズを指定します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "375px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/5ff7e/layout-shift-dont-6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/2aaaf/layout-shift-dont-6.webp 160w", "/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/85e47/layout-shift-dont-6.webp 320w", "/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/0669f/layout-shift-dont-6.webp 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/69538/layout-shift-dont-6.png 160w", "/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/72799/layout-shift-dont-6.png 320w", "/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/5ff7e/layout-shift-dont-6.png 375w"],
              "sizes": "(max-width: 375px) 100vw, 375px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e4e6cff7e9b4fbc0a7fd92cec160fd4d/5ff7e/layout-shift-dont-6.png",
              "alt": "悪い例のアニメーション",
              "title": "悪い例のアニメーション",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`DON'T: インフィニティスクロールする要素より下の要素は、各要素が表示されるタイミングで画面から消えてしまうため、操作できなくなります。`}</p>
    <h3 {...{
      "id": "-webフォントを適切に読み込む",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-web%E3%83%95%E3%82%A9%E3%83%B3%E3%83%88%E3%82%92%E9%81%A9%E5%88%87%E3%81%AB%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%82%80",
        "aria-label": " webフォントを適切に読み込む permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`✅ Webフォントを適切に読み込む`}</h3>
    <p>{`代替フォント(デバイスフォント)からWebフォントに表示が変更される際に、レイアウトシフトが起こります。ネイティブアプリのように予め端末にインストールできる場合にはこの問題は起こりません。`}</p>
    <p>{`対策としてWebフォントを使う前に、SVGにできないか検討します。また、Webページで利用する際にはfont-display: optionalを指定し、Webフォントは取得できた時のみ表示することも有効です。`}</p>
    <h2 {...{
      "id": "4-ネットワーク",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF",
        "aria-label": "4 ネットワーク permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`4 ネットワーク`}</h2>
    <p>{`ネットワークからのレスポンスが遅いと、最悪の場合には、画面に何も表示されないということが起こりえます。パフォーマンスを改善する際に、ネットワーク経由のレスポンス速度の最適化は根本的かつ重要な項目です。`}</p>
    <h3 {...{
      "id": "41-レスポンスを遅延させる無駄な処理を省く",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#41-%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9%E3%82%92%E9%81%85%E5%BB%B6%E3%81%95%E3%81%9B%E3%82%8B%E7%84%A1%E9%A7%84%E3%81%AA%E5%87%A6%E7%90%86%E3%82%92%E7%9C%81%E3%81%8F",
        "aria-label": "41 レスポンスを遅延させる無駄な処理を省く permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`4.1 レスポンスを遅延させる無駄な処理を省く`}</h3>
    <p>{`ネットワークやバックエンドアプリケーションにレスポンスを遅延させる無駄な処理がないか確認しましょう。例えば、多すぎるリダイレクトや不効率なネットワーク経路、サーバーアプリケーションでの時間のかかる処理、重いクエリなどです。`}</p>
    <p>{`レスポンス速度の目安として、エンドユーザー(端末)からのリクエストに対して、100ミリ秒以下で返却完了することを目指しましょう。`}</p>
    <h3 {...{
      "id": "42-キャッシュして無駄な計算処理を省く",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#42-%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%81%97%E3%81%A6%E7%84%A1%E9%A7%84%E3%81%AA%E8%A8%88%E7%AE%97%E5%87%A6%E7%90%86%E3%82%92%E7%9C%81%E3%81%8F",
        "aria-label": "42 キャッシュして無駄な計算処理を省く permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`4.2 キャッシュして無駄な計算処理を省く`}</h3>
    <p>{`何度リクエストしても同じ内容を返却するレスポンスは、できる限り長くキャッシュします。
効率的にデータをキャッシュするには、エンドポイントを静的(記事内容などリクエストごとに変化しないもの)、動的(ユーザー情報などリクエストごとに変化するもの)へと分類することが有効です。`}</p>
    <p>{`また、アクセスに対して地理の影響を受けず、安定したレスポンスをできるようにします。そのため主要拠点にサーバーを配置したり、CDNを利用したりします。特に検索エンジンのクローラーからのリクエストが重要なサービスにおいては、積極的にこれらの対応をしましょう。`}</p>
    <p>{`参考資料: `}<a parentName="p" {...{
        "href": "https://speakerdeck.com/herablog/using-cdn-to-improve-web-performance/"
      }}>{`CDNフル活用でつくる、高速Webアプリ`}</a></p>
    <h3 {...{
      "id": "43-ネットワークリクエストの優先度は最適されているか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#43-%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E5%84%AA%E5%85%88%E5%BA%A6%E3%81%AF%E6%9C%80%E9%81%A9%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%81%8B",
        "aria-label": "43 ネットワークリクエストの優先度は最適されているか permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`4.3 ネットワークリクエストの優先度は最適されているか`}</h3>
    <p>{`アプリケーションから発行されるネットワークリクエストが最適化されているか確認します。例えば、複数のリクエストを並列でなく全て同期的にリクエストしている、あるいはレスポンスのデータ量が大きい場合には遅延する可能性があります。`}</p>
    <p>{`リクエストの優先度が効率的に設定されているか、返却内容から無駄なデータを省いたり、エンドポイントを分割したりデータ量を減らせないかなど確認します。`}</p>
    <h2 {...{
      "id": "5-アプリケーション",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
        "aria-label": "5 アプリケーション permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`5 アプリケーション`}</h2>
    <h3 {...{
      "id": "51-アプリケーションのファイルサイズを小さくする",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#51-%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%B5%E3%82%A4%E3%82%BA%E3%82%92%E5%B0%8F%E3%81%95%E3%81%8F%E3%81%99%E3%82%8B",
        "aria-label": "51 アプリケーションのファイルサイズを小さくする permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`5.1 アプリケーションのファイルサイズを小さくする`}</h3>
    <p>{`ネットワーク回線速度や端末ディスク容量は年々改善され、アプリケーションのファイルサイズ自体が大きな問題になることは少なくなっています。`}</p>
    <p>{`しかし、アクティブなユーザーほどネットワークやディスクを消費するため、同じ内容であればできる限りファイルサイズを小さくした方がよいでしょう。利用者にとっては、表示速度やダウンロード速度、メモリ使用量、ディスク使用量など様々なメリットがあります。`}</p>
    <p>{`パフォーマンスバジェットを設け、ファイルサイズを作業単位やリリース前後で監視することも有効です。パフォーマンスバジェットを設定する際には、競合他社と比較したり、一般的なガイドラインを目安にしたりします。`}</p>
    <h3 {...{
      "id": "52-アプリケーションがクラッシュしない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#52-%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%8C%E3%82%AF%E3%83%A9%E3%83%83%E3%82%B7%E3%83%A5%E3%81%97%E3%81%AA%E3%81%84",
        "aria-label": "52 アプリケーションがクラッシュしない permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`5.2 アプリケーションがクラッシュしない`}</h3>
    <p>{`アプリケーション利用時に画面が固まったり、クラッシュしてしまったりすることは利用者が行ったアクションを中断し、ストレスを与えてしまいます。`}</p>
    <p>{`アプリケーションの状態が再現できるようにした上で、エラーログは集計され、クラッシュフリー率は99%以上であることが望ましいでしょう。`}</p>
    <h3 {...{
      "id": "53-uiスレッドを必要以上に長期間専有しない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#53-ui%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89%E3%82%92%E5%BF%85%E8%A6%81%E4%BB%A5%E4%B8%8A%E3%81%AB%E9%95%B7%E6%9C%9F%E9%96%93%E5%B0%82%E6%9C%89%E3%81%97%E3%81%AA%E3%81%84",
        "aria-label": "53 uiスレッドを必要以上に長期間専有しない permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`5.3 UIスレッドを必要以上に長期間専有しない`}</h3>
    <p>{`UIスレッドを長時間専有する処理があると、画面が固まったり、スクロールが遅延したりと快適な閲覧ができなくなってしまいます。`}</p>
    <p>{`無駄な処理を省いたり、処理自体を細かく分けたり、他のスレッドに処理を移したりするとよいでしょう。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      